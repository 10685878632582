import React from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import { SiteTitleQuery } from "../../graphql-types"

const NotFoundPage: React.FC<PageProps> = ({ data, location }) => {
  const { title } = useStaticQuery(graphql`
    query SiteTitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const meta = [
    {
      name: "robots",
      content: "noindex",
    },
  ]

  const c: React.ReactNode = (
    <>
      <SEO title="404: Not Found" meta={meta} />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </>
  )

  return (
    <Layout location={location} title={title}>
      {c}
    </Layout>
  )
}

export default NotFoundPage
